import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import { graphql, Link } from "gatsby";
import format from "date-fns/format";
import Img from "gatsby-image";

// hooks and cart
import { cartContext } from "providers/CartProvider";

import Layout from "components/Layout";
import SEO from "components/Seo";
import ClassRequirements from "components/ClassRequirements";
import ScheduleItem from "components/ScheduleItem";
import { HTMLContent } from "components/Content";

import {
  ClassCost,
  MonthlyScheduleGroup,
  MonthlyScheduleList,
  MonthlyScheduleHeader,
  StyledPrevNext,
} from "styles/styles";

const ClassSchedulePage = ({ data, pageContext }) => {
  const [scheduleData, setScheduleData] = useState([]);
  const siteContext = useContext(cartContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.GATSBY_API_URL}/courses/schedules`,
          {
            validateStatus(status) {
              return status < 500; // Resolve only if the status code is less than 500
            },
          }
        );

        // console.log("Main -> response.data", response.data);
        setScheduleData(response.data);
      } catch (e) {
        siteContext.makeToast("Unable to fetch schedule data.", "error");
      }
    }

    fetchData();
  }, []);

  // const pgClasses = data.pgClasses.nodes ? data.pgClasses.nodes : [];

  const { slug } = pageContext;
  const { careers } = data.careersDetails.nodes[0].data;

  const career = careers.find(x => x.career_slug === slug);

  const allCareers = careers.map(x => ({
    slug: x.career_slug,
    title: x.career_title.text,
  }));

  const prevCareer = allCareers.reduce((acc, curr, i, arr) => {
    if (curr.slug === slug) {
      if (i === 0) {
        return arr[arr.length - 1];
      }

      return arr[i - 1];
    }

    return acc;
  }, {});

  const nextCareer = allCareers.reduce((acc, curr, i, arr) => {
    if (curr.slug === slug) {
      if (i === arr.length - 1) {
        return arr[0];
      }

      return arr[i + 1];
    }

    return acc;
  }, {});

  const classesInCategory = scheduleData.filter(x => {
    const catSlugs = x.coursecats.map(c => c.slug);
    return catSlugs.includes(slug);
  });
  // console.log("ClassSchedulePage -> classesInCategory", classesInCategory);

  const formattedClasses =
    classesInCategory && classesInCategory.length > 0
      ? classesInCategory.map(cl => {
          // const { date, datestop, timestart, timeend, id__normalized } = cat;
          const { date } = cl;
          const rawDate = Date.parse(date);

          const year = format(rawDate, "yyyy");
          const month = format(rawDate, "MMM");
          const day = format(rawDate, "dd");

          return {
            year,
            month,
            day,
            ...cl,
          };
        })
      : [];

  const classesByMonth = formattedClasses.reduce((acc, curr) => {
    const theMonthNum = format(Date.parse(curr.date), "M");
    const theYear = curr.year;

    const matchItem = acc.find(x => {
      const thisMonth = x.monthNum ? x.monthNum : null;

      return x.year === theYear && thisMonth && thisMonth === theMonthNum;
    });

    // new month/year
    if (!matchItem) {
      const tempMonthObj = {
        monthNum: theMonthNum,
        monthAbbrev: curr.month,
        year: theYear,
        classes: [curr],
      };

      return acc.concat(tempMonthObj);
    }

    // otherwise, just add to an existing month/year item
    const newClasses = matchItem.classes.concat(curr);
    matchItem.classes = newClasses;

    return acc;
  }, []);

  const cartState = useContext(cartContext);

  const addToCart = (scheduleID, qty = 1) => {
    const attendeesArr = [];
    for (let z = 0; z < qty; z++) {
      attendeesArr.push({
        name: "",
        email: "",
      });
    }

    const newItem = {
      id: scheduleID,
      attendees: attendeesArr,
      qty,
    };

    cartState.updateProducts(newItem);
  };

  return (
    <Layout pageInfo={{ pageName: slug }}>
      <SEO title={`${career.career_title.text} Program`} />
      <section className="class-main-details">
        <Container>
          <Row>
            <Col>
              <h2>{career.career_title.text}</h2>

              <HTMLContent content={career.career_main_text.html} />

              <ClassRequirements
                content={career.career_requirements_text.html}
              />

              <ClassCost>
                <h4 className="subhead pb-2 mb-1">Cost:</h4>
                <p>{career.career_cost}</p>
              </ClassCost>
            </Col>
            <Col xs="12" md={{ span: 5 }}>
              <Img fluid={career.career_img.localFile.childImageSharp.fluid} />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bluedark">
        <Container>
          <Row>
            <Col>
              <div className="schedule">
                <h3 id="schedule">
                  Class Schedule: {career.career_title.text}
                </h3>

                {classesByMonth &&
                  classesByMonth.map((monthData, i) => {
                    if (monthData.classes.length > 0) {
                      return (
                        <MonthlyScheduleGroup key={`month-${i}`}>
                          <MonthlyScheduleHeader>
                            <h4 className="subhead">
                              {`${monthData.monthAbbrev} ${monthData.year}`}
                            </h4>
                          </MonthlyScheduleHeader>

                          <MonthlyScheduleList>
                            {scheduleData.length > 0 && monthData.classes ? (
                              monthData.classes.map((item, z) => (
                                <li key={z}>
                                  <ScheduleItem
                                    item={item}
                                    addToCart={addToCart}
                                    cartState={cartState}
                                  />
                                </li>
                              ))
                            ) : (
                              <p>No classes currently scheduled.</p>
                            )}
                          </MonthlyScheduleList>
                        </MonthlyScheduleGroup>
                      );
                    }
                    return null;
                  })}

                {!scheduleData && (
                  <p>
                    No upcoming classes scheduled in this category. Check back
                    soon!
                  </p>
                )}

                {prevCareer && nextCareer && (
                  <StyledPrevNext>
                    <div className="prevnext-inner">
                      <h4>Prev/Next Branches:</h4>
                      <Link
                        to={`/class-schedules/${prevCareer.slug}`}
                        className="btn btn-secondary prev"
                      >{`< ${prevCareer.title}`}</Link>

                      <Link
                        to={`/class-schedules/${nextCareer.slug}`}
                        className="btn btn-secondary nxt"
                      >{`${nextCareer.title} >`}</Link>
                    </div>
                  </StyledPrevNext>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default ClassSchedulePage;

export const query = graphql`
  query {
    careersDetails: allPrismicCareerpaths {
      nodes {
        uid
        prismicId
        data {
          title {
            text
          }
          careers {
            career_title {
              text
            }
            career_main_text {
              html
            }
            career_requirements_text {
              html
            }
            career_slug
            career_cost
            career_img {
              localFile {
                childImageSharp {
                  fluid(quality: 75) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }

    # pgClasses: allMyschedules {
    #   nodes {
    #     id__normalized
    #     title
    #     totalseats
    #     date
    #     timestart
    #     datestop
    #     price
    #     online
    #     note
    #     coursecats {
    #       slug
    #       _pivot_coursecat_id
    #     }
    #     orders {
    #       qty
    #       schedule_id
    #     }
    #   }
    # }
  }
`;

/*
query($slug: String!) ... 

pgClasses: allCoursecats(filter: { slug: { eq: $slug } }) {
  nodes {
    id
    id__normalized
    slug
    title
    schedules {
      title
      date
      datestop
      timestart
      timeend
      note
      price
      online
      id__normalized
    }
  }
}
*/
