import React from "react";
import { HTMLContent } from "components/Content";
import { StyledClassRequirements } from "styles/styles";

const ClassRequirements = ({ content }) => (
  <StyledClassRequirements>
    <h4 className="subhead pb-2 mb-2 mt-4">Requirements:</h4>
    <HTMLContent content={content} />
  </StyledClassRequirements>
);

export default ClassRequirements;
