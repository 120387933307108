import React, { useState } from "react";
import { FaCartPlus, FaSignInAlt } from "react-icons/fa";
import { Link } from "gatsby";

import { StyledScheduleItem } from "styles/styles";
import { formatToMilitaryDate, getTotalDays } from "helpers";

const ScheduleItem = ({ item, addToCart, cartState }) => {
  // console.log("ScheduleItem -> item", item);
  const [qty, setQty] = useState(1);

  const inCart =
    cartState && cartState.cart && cartState.cart.find(x => x.id === item.id);

  const handleQtyChange = e => {
    setQty(e.target.value);
  };

  const totalEnrolled =
    (item &&
      item.orders &&
      item.orders.reduce((acc, cur) => acc + cur.qty, 0)) ||
    0;

  const availableSeats = item.totalseats - totalEnrolled;

  return (
    <StyledScheduleItem>
      <div className="details">
        <h5>{item.title}</h5>
        <p>
          <b>Start Time:</b> {item.timestart}
        </p>

        {item.datestop && (
          <p>
            <b>End Date:</b> {formatToMilitaryDate(item.datestop)} <br />
            (This is a{" "}
            {getTotalDays(Date.parse(item.date), Date.parse(item.datestop))} day
            class)
          </p>
        )}

        <p>
          <b>Price:</b> ${item.price}
        </p>

        {item.note && <p className="mt-2">Note: {item.note}</p>}

        {availableSeats > 0 ? (
          <div>
            <p>Available Seats: {availableSeats}</p>
            {!item.online ? (
              <div className="qty-box">
                {!inCart && (
                  <>
                    <label htmlFor={`qty_${item.id}`}>Qty:</label>
                    <input
                      type="number"
                      value={qty}
                      name={`qty_${item.id}`}
                      className="form-control"
                      onChange={handleQtyChange}
                    />
                  </>
                )}

                <button
                  type="button"
                  className={`btn btn-sm ${
                    inCart ? "btn-secondary" : "btn-primary"
                  }`}
                  onClick={() => addToCart(item.id, qty)}
                  disabled={inCart}
                >
                  <FaCartPlus size="18px" />{" "}
                  {inCart ? "In Cart" : "Add To Cart"}
                </button>
              </div>
            ) : (
              <p>
                <b className="text-warning">Online only!</b> <br />
                <Link
                  to="/inservice-renewals/"
                  className="btn btn-primary my-2"
                >
                  <FaSignInAlt size="18px" /> Online Classroom Instructions -
                  CLICK HERE
                </Link>
              </p>
            )}
          </div>
        ) : (
          <div>
            <p>This class is full.</p>
          </div>
        )}
      </div>
      <div className="datetime">
        <span className="day">{item.day}</span>
        <span className="month">{item.month}</span>
        <span className="year">{item.year}</span>
      </div>
    </StyledScheduleItem>
  );
};
export default ScheduleItem;
