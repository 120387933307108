import styled from "styled-components";

export const StyledDashboardPurchaseItem = styled.div`
  padding: 1rem;
  margin: 0 0 1rem;
  background: #fff;
  border: 1px solid #ccc;
  border-width: 0 1px 2px 0;

  h5 {
    font-size: 1.1rem;
  }

  p {
    font-size: 13px;
    margin: 0;
    padding: 0;
    line-height: 1.33;
  }
`;

export const ClassCost = styled.div`
  margin: 0.5rem 0;
  padding: 0.5rem 0;

  h4 {
    margin: 0;
    padding: 0;
    line-height: 0;
  }

  p {
    font-size: 1.3rem;
    line-height: 1.1;
  }
`;

export const StyledClassRequirements = styled.div`
  border: 2px dotted rgba(19, 49, 101, 0.3);
  border-width: 2px 0;
  margin: 0.5rem 0;
  padding: 0.5rem 0;

  h4 {
    margin: 0;
    padding: 0;
    line-height: 0;
  }

  ul {
    margin: 0 0 2rem;
    padding: 0 0 0 1.1rem;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.3;
  }
`;

export const MonthlyScheduleGroup = styled.div`
  margin: 1.5rem 0 0;
`;

export const MonthlyScheduleHeader = styled.div`
  margin: 2.5rem 0 0;

  h4 {
    color: #66bffd !important;
  }
`;

export const MonthlyScheduleList = styled.ul`
  list-style: none;
  padding-left: 0;
  li {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2);
    &::before {
      content: none;
    }
  }
`;

export const StyledScheduleItem = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 0.66rem 0;
  justify-content: flex-end;

  div.details {
    h5 {
      margin: 0 0 6px;
    }
    p {
      margin: 0;
      padding: 0;
    }
  }

  div.datetime {
    background: rgba(250, 250, 250, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    padding: 0.5rem 0;
    width: 80px;
    min-width: 80px;
    line-height: 1.1;

    span.day {
      font-size: 1.4rem;
      font-weight: 800;
    }

    span.month {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.1rem;
    }

    span.year {
      font-size: 0.9rem;
    }
  }

  div.qty-box {
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    margin: 14px 8px 0 0;

    label {
      font-size: 15px;
      font-weight: 600;
      margin: 0 8px 0 0;
      text-transform: uppercase;
      line-height: 1.1;
    }

    input {
      display: inline-block;
      height: 30px;
      line-height: 1.1;
      margin: 0 6px 0 0;
      padding: 0 2px;
      text-align: center;
      width: 50px;
    }
  }
`;

export const StyledPrevNext = styled.div`
  margin: 2.5rem 0 0;
  padding: 1rem;
  background: rgba(250 250 250 / 0.03);

  div.prevnext-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }

    h4 {
      font-size: 18px;
      text-align: center;
      border: none;
      order: 0;
      margin: 0 0 1rem;
      line-height: 1;
      &:after {
        content: none;
      }

      @media (min-width: 768px) {
        order: 1;
        margin: 0;
      }
    }

    a.btn {
      order: 1;
      margin-bottom: 1rem;
      font-weight: 600;

      @media (min-width: 768px) {
        order: 0;
        margin: 0;

        &.nxt {
          order: 2;
        }
      }
    }
  }
`;

export const StyledAllClassesBox = styled.div`
  margin: 2.5rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    &:after {
      content: none;
    }
  }
`;

/* calendar custom styles and print styles */
export const StyledEvent = styled.div`
  line-height: 1;
  font-size: 0.8rem;
  margin: 0 0 1.4rem;
  border-bottom: 1px solid #ccc;
  padding: 0 0 1rem;
  display: flex;
  flex-direction: row;

  @media print {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    padding: 10px;
    color: #111;
    margin: 0 0 10px;
  }

  h5 {
    font-size: 1.33rem;
  }

  p {
    font-size: 1.1rem;
    margin: 0 0 1px;
    white-space: normal;
    line-height: 1.33;
    font-weight: 600;

    @media print {
      font-weight: 400;
      font-size: 12px;
      line-height: 1.33;
    }
  }

  p.e-time {
    font-size: 1.1rem;
    line-height: 1.5;
    font-weight: normal;
    margin: 8px 0 0;

    @media print {
      line-height: 1.2;
      font-size: 12px;
    }
  }

  p.e-desc {
    font-size: 1rem;
    line-height: 1.25;
    font-weight: normal;
    margin: 8px 0 0;

    @media print {
      line-height: 1.2;
      font-size: 12px;
    }
  }

  div.bigdate {
    background: rgba(250, 250, 250, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    padding: 0.5rem 0;
    width: 80px;
    min-width: 80px;
    line-height: 1.1;
    border: 1px solid #999;

    &.is-event {
      background: rgba(220, 220, 220, 0.5);
    }

    span {
      display: block;
    }

    span.day {
      font-size: 1.4rem;
      font-weight: 800;
    }

    span.month {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.1rem;
    }
  }
`;

export const StyledCalHeader = styled.div`
  @media print {
    background-color: #e1e1e1;
    font-weight: 600;
    font-size: 11px;
    text-transform: uppercase;
  }
`;
